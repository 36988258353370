// React
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";

// UI
import { Avatar, Button, Divider } from "rsuite";
import { FaBriefcase } from "react-icons/fa6";

// 3rd Party
import { DateTime } from "luxon";

const WorkHistory = ({ initializedJobModal }) => {
  const { user } = useOutletContext() as any;
  const [visibleJobsCount, setVisibleJobsCount] = useState(2);

  const toggleVisibleJobs = () => {
    if (visibleJobsCount === 2) {
      setVisibleJobsCount(user.jobs.length);
    } else {
      setVisibleJobsCount(2);
    }
  };

  return (
    <div className="tw-w-full tw-p-3 tw-overflow-y-auto">
      {user.jobs ? (
        <>
          {user.jobs.slice(0, visibleJobsCount).map((job, idx) => (
            <div
              key={job.id}
              onClick={() => initializedJobModal(job)}
              className="hover:tw-cursor-pointer hover:tw-opacity-70"
            >
              <div className="tw-mx-2">
                <div className="tw-flex">
                  <div className="tw-mr-6">
                    <Avatar className="tw-size-12">
                      <FaBriefcase size={24} />
                    </Avatar>
                  </div>
                  <div className="tw-flex tw-flex-col tw-flex-grow">
                    <div className="tw-flex tw-justify-between">
                      <h6 className="tw-mb-1 tw-text-sm">{job.name}</h6>
                      <p className="tw-text-xs tw-font-medium tw-mb-1">
                        {DateTime.fromISO(job.start_date).toFormat("MMM yyyy")}{" "}
                        -
                        {job.end_date
                          ? DateTime.fromISO(job.end_date).toFormat("MMM yyyy")
                          : "Present"}
                      </p>
                    </div>
                    <p className="tw-font-medium tw-text-sm tw-mb-1">
                      {job.company.name}
                    </p>
                  </div>
                </div>
                <p className="tw-text-sm tw-my-4">{job.description}</p>
              </div>

              {idx !== visibleJobsCount - 1 && idx !== user.jobs.length - 1 && (
                <Divider className="tw-my-4" />
              )}
            </div>
          ))}

          {user.jobs.length > 2 && (
            <div className="tw-text-center tw-mt-4">
              <Button appearance="link" onClick={toggleVisibleJobs}>
                {visibleJobsCount === 2 ? "View More" : "View Less"}
              </Button>
            </div>
          )}
        </>
      ) : (
        <div className="tw-flex tw-mb-4">
          <p>
            Showcase your journey! Whether it's your career highlights or side
            hustles, this is your space to tell your story.
          </p>
        </div>
      )}
    </div>
  );
};

export default WorkHistory;
