// React
import React from "react";
import { useOutletContext } from "react-router-dom";

// FutureLab
import { FutureLabApi } from "../../util/api";
import FieldError from "../ui/FieldError";
import Toaster from "components/shared/Toaster";
import { UserDeletion } from "./types";
import { deletionSchema } from "./schema";

// UI
import { Button, Checkbox, CheckboxGroup, Divider, Form, Modal } from "rsuite";

// 3rd Party
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { map } from "lodash";
import { ErrorMessage } from "@hookform/error-message";

const reasonOptions = [
  "No longer need mentoring",
  "Didn’t find enough relevant mentors",
  "Unsatisfied with the mentor experience",
  "Scheduling sessions was difficult",
  "Found a different platform",
  // "Other", Future Use
];

const DeleteAccountModal = ({ showDeleteConfirm, setShowDeleteConfirm }) => {
  const { variables } = useOutletContext() as any;

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<UserDeletion>({
    resolver: yupResolver(deletionSchema),
    defaultValues: {
      reason_for_leaving: [],
    },
  });

  const onSubmit = (data) => {
    data.reason_for_leaving = data.reason_for_leaving.join(";");
    FutureLabApi({
      method: "post",
      path: "/users/current/delete_account",
      options: data,
      success: ({ data }) => {
        Toaster({
          type: "success",
          msg: "Account has been deleted",
        });
        window.location.href = `${variables?.PWA_URL}/users/logout`;
      },
      error: (err) => {
        const { error } = err.response.data;
        Toaster({
          type: "error",
          msg: error || "Oops, something went wrong!",
        });
      },
    });
  };

  return (
    <Modal
      open={showDeleteConfirm}
      backdrop="static"
      onClose={() => setShowDeleteConfirm(false)}
      size="xs"
    >
      <Modal.Header>
        <Modal.Title className="tw-text-xl tw-font-medium">
          Delete Account
        </Modal.Title>
        <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />
      </Modal.Header>
      <Modal.Body className="tw-mt-8">
        <h1 className="tw-text-xl tw-font-semibold tw-text-[#0a0a0a]">
          We're sorry to see you go
        </h1>
        <p className="tw-text-[#0a0a0a]">
          If you're sure you want to proceed, we’d love to know why you’re
          leaving.
        </p>

        <Form>
          <Controller
            name="reason_for_leaving"
            control={control}
            render={({ field }) => (
              <CheckboxGroup name="reason_for_leaving" {...field}>
                {map(reasonOptions, (reason) => (
                  <Checkbox key={reason} {...field} value={reason}>
                    <span style={{ fontSize: 14 }}>{reason}</span>
                  </Checkbox>
                ))}
              </CheckboxGroup>
            )}
          />
          <ErrorMessage
            errors={errors}
            name="reason_for_leaving"
            as={FieldError}
          />
        </Form>
        <div className="tw-text-right">
          <Button
            className="tw-bg-[#d12f1d]"
            onClick={handleSubmit(onSubmit)}
            appearance="primary"
          >
            Delete
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteAccountModal;
