// React
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";

// FutureLab
import Api, { FutureLabApi } from "../../util/api";
import JobModal from "./JobModal";
import RemoveJobConfirmationModal from "./RemoveJobConfirmationModal";
import Toaster from "components/shared/Toaster";
import WorkHistory from "./WorkHistory";
import { JobInput } from "./types";

// UI
import { Button } from "rsuite";

const fetchCompanies = async (query = "") => {
  return await Api.get("/companies", { search: query });
};

const WorkExperience = () => {
  const { refreshUser } = useOutletContext() as any;
  const [companies, setCompanies] = useState<[]>();
  const [job, setJob] = useState<JobInput | null>();
  const [loading, setLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const closeJobModal = () => setShowEditModal(false);
  const closeJobConfirmationModal = () => setShowDeleteConfirm(false);

  const initializedDeleteJobConfimartionModal = () =>
    setShowDeleteConfirm(true);

  const handleSearch = async (query) => {
    setLoading(true);
    try {
      const { data } = await fetchCompanies(query);
      setCompanies(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const initializedJobModal = async (selectedJob = null) => {
    await handleSearch(selectedJob?.company?.name);
    setJob(selectedJob);
    setShowEditModal(true);
  };

  const deleteJob = async () => {
    await FutureLabApi({
      method: "delete",
      path: `/jobs/${job.id}`,
      success: () => {
        refreshUser();
        Toaster({
          type: "success",
          msg: "Job successfully deleted",
        });
      },
    });

    closeJobConfirmationModal();
    closeJobModal();
  };

  return (
    <>
      <div className="tw-flex tw-justify-between">
        <h6 className="tw-text-base">Work History</h6>
        <Button appearance="link" onClick={() => initializedJobModal()}>
          Add new
        </Button>
      </div>
      <WorkHistory initializedJobModal={initializedJobModal} />
      <JobModal
        job={job}
        companies={companies}
        handleSearch={handleSearch}
        showEditModal={showEditModal}
        closeJobModal={closeJobModal}
        loading={loading}
        initializedDeleteJobConfimartionModal={
          initializedDeleteJobConfimartionModal
        }
      />
      <RemoveJobConfirmationModal
        showDeleteConfirm={showDeleteConfirm}
        closeJobConfirmationModal={closeJobConfirmationModal}
        deleteJob={deleteJob}
      />
    </>
  );
};

export default WorkExperience;
