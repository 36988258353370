// React
import React from "react";
import { useNavigate } from "react-router-dom";

// FutureLab
import useEditRoute from "./useEditRoute";

// UI
import { InputPicker } from "rsuite";

const MobileNav = () => {
  const { navItems, activeRoute } = useEditRoute();
  const currentActive = activeRoute?.path;
  const navigate = useNavigate();

  return (
    <InputPicker
      size="lg"
      block
      defaultValue={currentActive}
      onSelect={(value) => navigate(`.${value}`)}
      data={navItems.map((item) => ({ label: item.label, value: item.path }))}
    />
  );
};

export default MobileNav;
