import React from "react";

// UI
import { Button, Modal } from "rsuite";
import { FaExclamationTriangle } from "react-icons/fa";

const RemoveCollegeConfirmationModal = ({
  showDeleteConfirm,
  closeCollegeConfirmationModal,
  deleteCollege,
}) => {
  return (
    <Modal backdrop="static" open={showDeleteConfirm} size="xs">
      <Modal.Body className="tw-text-center">
        <FaExclamationTriangle className="tw-mr-4" color="#d12f1d" />
        Are you sure you want to delete this?
      </Modal.Body>
      <Modal.Footer className="text-center">
        <Button onClick={deleteCollege} appearance="primary">
          Confirm
        </Button>
        <Button onClick={closeCollegeConfirmationModal} appearance="default">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveCollegeConfirmationModal;
