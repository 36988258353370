// React
import React from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";

// FutureLab
import { UserProvider, useUser } from "../contexts/user";
import EditPageLayout from "./edit_mentor_profile/EditPageLayout";
import Availability from "./edit_mentor_profile/Availability";
import GenericMsgLoader from "./shared/GenericMsgLoader";
import Account from "./edit_mentor_profile/Account";
import Profile from "./edit_mentor_profile/Profile";
import Matching from "./edit_mentor_profile/Matching";
import Experience from "./edit_mentor_profile/Experience";
import Notifications from "./edit_mentor_profile/Notifications";

const EditProfile = ({ variables }) => {
  return (
    <BrowserRouter>
      <UserProvider>
        <Routes>
          <Route
            path={`/users/${variables?.userSlug}/edit/*`}
            element={<EditOutlet variables={variables} />}
          >
            <Route index element={<Account />} />
            <Route path="profile" element={<Profile />} />
            <Route path="mentorship" element={<Matching />} />
            <Route path="experience" element={<Experience />} />
            <Route path="availability" element={<Availability />} />
            <Route path="notification" element={<Notifications />} />
          </Route>
        </Routes>
      </UserProvider>
    </BrowserRouter>
  );
};

const EditOutlet = ({ variables }) => {
  const { user, setUser, refreshUser } = useUser();

  if (!user) return <GenericMsgLoader center content="loading" />;

  return (
    <EditPageLayout>
      <Outlet context={{ variables, user, setUser, refreshUser }} />
    </EditPageLayout>
  );
};

export default EditProfile;
