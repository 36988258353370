// React
import React, { ReactNode, useMemo } from "react";

// UI
import { Nav } from "rsuite";
import { Icon } from "@rsuite/icons";

const Navigation = ({
  navItems,
  activeKey = null,
  handleSelect = null,
  ...navProps
}) => {
  const NavComponents = useMemo<ReactNode[]>(() => {
    const renderNavItems = (navItems) => {
      return navItems.map((item, index) => {
        if (item.children) {
          return (
            <Nav.Menu
              key={item.eventKey}
              placement="rightStart"
              eventKey={item.eventKey}
              title={<span className="tw-font-medium">{item.label}</span>}
              icon={item.icon && <Icon as={item.icon} />}
            >
              {renderNavItems(item.children)}
            </Nav.Menu>
          );
        }
        return (
          <Nav.Item
            key={item.eventKey}
            eventKey={item.eventKey}
            icon={item.icon && <Icon as={item.icon} />}
            className="tw-flex tw-justify-start"
          >
            <span className="tw-font-medium">{item.label}</span>
          </Nav.Item>
        );
      });
    };
    return renderNavItems(navItems) || null;
  }, [navItems, activeKey]);

  return (
    <Nav
      activeKey={activeKey}
      onSelect={(eventKey) => handleSelect(eventKey)}
      {...navProps}
    >
      {NavComponents}
    </Nav>
  );
};

export default Navigation;
