// React
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// FutureLab
import { PortalApi } from "../../../util/portal_api";
import DeletionModal from "./ui/DeletionModal";
import DownloadButton from "./DownloadButton";

// UI
import {
  MdAssignmentTurnedIn,
  MdOutlineDelete,
  MdOutlineEventBusy,
  MdOutlineFilterAlt,
  MdOutlineSend,
} from "react-icons/md";
import {
  ButtonToolbar,
  IconButton,
  Message,
  Nav,
  Table,
  toaster,
  Tooltip,
  Whisper,
} from "rsuite";

// 3rd Party
import mixpanel from "mixpanel-browser";
import moment from "moment";

const { Column, HeaderCell, Cell } = Table;

const ApplicationDetails = () => {
  const { applicationId } = useParams();

  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState({});
  const [status, setStatus] = useState("applied");
  const [application, setApplication] = useState([]);
  const [applicants, setApplicants] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [applicant, setApplicant] = useState(null);

  let fileName = `${application["name"]}(${status}) ${moment().format(
    "DD-MM-YYYY"
  )}.csv`;

  useEffect(() => {
    getApplicants(status);
  }, [status]);

  const getApplicants = (status) => {
    setLoading(true);
    PortalApi({
      method: "get",
      path: "/applicants",
      options: { application_id: applicationId, status: status },
      success: ({ data }) => {
        setLoading(false);
        setApplicants(data.applicants);
        setQuestions(data.questions);
        setTotalCount(data.total_count);
        setApplication(data.application);
      },
    });
  };

  const patchApplicantStatus = (statusChange, applicantId) => {
    PortalApi({
      method: "patch",
      path: "/applicants/update_status",
      options: { applicant_id: applicantId, status: statusChange },
      success: ({ data }) => {
        getApplicants(status);
        toaster.push(
          <Message showIcon type="success">
            {data.message}
          </Message>
        );
      },
      error: ({ data }) => {
        toaster.push(
          <Message showIcon type="error">
            {data?.message}
          </Message>
        );
      },
    });
  };

  const postSendRejection = (applicantId) => {
    PortalApi({
      method: "post",
      path: "/applicants/send_rejection",
      options: { applicant_id: applicantId },
      success: ({ data }) => {
        toaster.push(
          <Message showIcon type="success">
            {data.message}
          </Message>
        );
      },
      error: ({ data }) => {
        toaster.push(
          <Message showIcon type="error">
            {data?.message}
          </Message>
        );
      },
    });
  };

  const handleSortStatus = async (statusChange, applicant) => {
    setLoading(true);
    setOpenModal(false);

    mixpanel.track("Application Action Button", {
      status: statusChange,
      "Application Name": application["name"],
      "Applicant User Id": applicant.user_id,
      "Applicant Name": applicant.full_name,
      "Total Applicants": application["total_applicants"],
    });

    try {
      await patchApplicantStatus(statusChange, applicant.id);

      if (statusChange === "rejected") {
        await postSendRejection(applicant.id);
      }
    } catch (error) {
      console.error("Error handling sort status:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = (id) => {
    setLoading(true);

    PortalApi({
      method: "delete",
      path: "/applicants",
      options: { applicant_id: id },
      success: ({ data }) => {
        setLoading(false);
        getApplicants(status);
        toaster.push(
          <Message showIcon type="success">
            {data.message}
          </Message>
        );
      },
      error: ({ data }) => {
        setLoading(false);
        toaster.push(
          <Message showIcon type="error">
            {data?.message}
          </Message>
        );
      },
    });
  };

  const handleSendInvite = (applicantId) => {
    setLoadingButton(() => ({ [applicantId]: true }));
    PortalApi({
      method: "post",
      path: "/applicants/send_invite",
      options: { applicant_id: applicantId, status: status },
      success: ({ data }) => {
        setLoadingButton(() => ({ [applicantId]: false }));
        toaster.push(
          <Message showIcon type="success">
            {data.message}
          </Message>
        );
        setLoadingButton(false);
      },
      error: ({ data }) => {
        setLoadingButton(() => ({ [applicantId]: false }));
        toaster.push(
          <Message showIcon type="error">
            {data?.message}
          </Message>
        );
        setLoadingButton(false);
      },
    });
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const TooltipButton = ({ onClick, icon, message, mode, color, id }) => {
    const tooltip = <Tooltip>{message}</Tooltip>;

    return (
      <Whisper
        placement="top"
        controlId="control-id-hover"
        trigger="hover"
        speaker={tooltip}
      >
        <IconButton
          id={id}
          size="md"
          onClick={onClick}
          appearance="primary"
          icon={icon}
          loading={loadingButton[id]}
          className={mode == status ? "tw-hidden" : ""}
          color={color}
        />
      </Whisper>
    );
  };

  const approveButtons = (rowData) => (
    <>
      <TooltipButton
        id={rowData.id}
        message={"Email invitation"}
        mode={"shortlisted"}
        icon={<MdOutlineSend />}
        color={"green"}
        onClick={() => handleSendInvite(rowData.id)}
      />
    </>
  );

  const actionButtons = (rowData) => (
    <>
      <TooltipButton
        id={rowData.id}
        message={"Shortlist"}
        mode={"shortlisted"}
        icon={<MdOutlineFilterAlt />}
        color={"blue"}
        onClick={() => handleSortStatus("shortlisted", rowData)}
      />
      <TooltipButton
        id={rowData.id}
        message={"Approve"}
        mode={"approved"}
        icon={<MdAssignmentTurnedIn />}
        color={"green"}
        onClick={async () => {
          await handleSortStatus("approved", rowData);
          await handleSendInvite(rowData.id);
        }}
      />
      <TooltipButton
        id={rowData.id}
        message={"Reject"}
        mode={"rejected"}
        icon={<MdOutlineEventBusy />}
        color={"red"}
        onClick={() => {
          setOpenModal(true);
          setApplicant(rowData);
        }}
      />
    </>
  );

  const deleteButtons = (rowData) => (
    <TooltipButton
      id={rowData.id}
      message={"Delete Applicant"}
      mode={"delete"}
      icon={<MdOutlineDelete />}
      color={"red"}
      onClick={() => handleDelete(rowData.id)}
    />
  );

  const NavTabs = ({ totalCount }) => {
    const {
      application_total,
      shortlisted_total,
      approved_total,
      rejected_total,
      draft_total,
    } = totalCount;

    return (
      <Nav
        appearance="subtle"
        activeKey={status}
        onSelect={setStatus}
        justified
        className="tw-mt-5 tw-mb-10"
      >
        <Nav.Item eventKey="applied" className="tw-text-center">
          Applicants ({application_total || 0})
        </Nav.Item>
        <Nav.Item eventKey="shortlisted" className="tw-text-center">
          Shortlisted ({shortlisted_total || 0})
        </Nav.Item>
        <Nav.Item eventKey="approved" className="tw-text-center">
          Approved ({approved_total || 0})
        </Nav.Item>
        <Nav.Item eventKey="rejected" className="tw-text-center">
          Rejected ({rejected_total || 0})
        </Nav.Item>
        <Nav.Item eventKey="draft" className="tw-text-center">
          Draft ({draft_total || 0})
        </Nav.Item>
      </Nav>
    );
  };

  return (
    <div className="tw-my-6 tw-py-4">
      <div className="tw-flex tw-justify-between">
        <h1 className="tw-text-xl tw-inline">{application["name"]}</h1>
        <DownloadButton
          data={applicants}
          fileName={fileName}
          questions={questions}
        />
      </div>

      <NavTabs totalCount={totalCount} />

      <Table
        data={applicants}
        wordWrap
        autoHeight
        cellBordered
        rowClassName="tw-text-sm"
        rowHeight={60}
        loading={loading}
        className="tw-my-6"
        affixHorizontalScrollbar
      >
        <Column align="left" verticalAlign="top" minWidth={250} flexGrow={2}>
          <HeaderCell>Name</HeaderCell>
          <Cell dataKey="full_name" />
        </Column>

        <Column align="left" verticalAlign="top" minWidth={250} flexGrow={3}>
          <HeaderCell>Email</HeaderCell>
          <Cell dataKey="email" />
        </Column>

        <Column align="left" verticalAlign="top" minWidth={200} flexGrow={2}>
          <HeaderCell>Applied on</HeaderCell>
          <Cell dataKey="applied_on" />
        </Column>

        {questions.map((question, idx) => (
          <Column
            align="left"
            verticalAlign="top"
            minWidth={300}
            flexGrow={2}
            key={idx}
            resizable
          >
            <HeaderCell>{question}</HeaderCell>
            <Cell>
              {(rowData) => (
                <p className="tw-text-sm montserrat">{rowData?.answers[idx]}</p>
              )}
            </Cell>
          </Column>
        ))}

        <Column
          align="center"
          verticalAlign="top"
          minWidth={150}
          flexGrow={1.5}
          fixed={"right"}
        >
          <HeaderCell>Action</HeaderCell>
          <Cell>
            {(rowData) => (
              <ButtonToolbar>
                {status == "rejected"
                  ? deleteButtons(rowData)
                  : status == "approved"
                  ? approveButtons(rowData)
                  : status === "draft"
                  ? null
                  : actionButtons(rowData)}
              </ButtonToolbar>
            )}
          </Cell>
        </Column>
      </Table>
      <DeletionModal
        open={openModal}
        handleModalClose={handleModalClose}
        clickHandler={() => handleSortStatus("rejected", applicant)}
        title={"Reject Applicant"}
        bodyMessage={
          "You are about to move this applicant to 'Rejected'. This action cannot be undone. Are you sure you want to reject?"
        }
      />
    </div>
  );
};

export default ApplicationDetails;
