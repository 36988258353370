import { useEffect, useMemo, useState } from "react";
import mixpanel from "mixpanel-browser";

const usePurchase = (packageId, userId, actionPath) => {
  const [promo, setPromo] = useState({
    promo: false,
    discount: 0,
  });

  const [quantity, setQuantity] = useState(1);
  const [promoCodeId, setPromoCodeId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(0);

  const urlParams = new URLSearchParams(window.location.search);


  // TODO: IF promo code is passed from URLParams
  // Consider moving validate_promo_code in PromoCodeChecker call to this level.
  useEffect(() => {
    const params = urlParams.get("promo_code");
    params && setPromoCodeId(params);
  }, []);

  // let isFixedCreditPackage = useMemo(
  //   () => packagePlan.id === 12,
  //   [packagePlan.id]
  // );

  // const price = useMemo(() => {
  //   const num = Number(packagePlan?.cost) * quantity;
  //   return num;
  // }, [packagePlan, quantity]);

  const discountPercentage = useMemo(() => {
    promo?.discount || promo?.promo ? promo?.discount : 0;
    return promo?.discount || 0;
  }, [promo]);

  const discountedAmount = useMemo(() => {
    const amount = price * (discountPercentage / 100);
    return amount;
  }, [price, discountPercentage]);

  const transactionFee = useMemo(() => {
    //price * 0.03 + 1
    const currPrice = price - discountedAmount;
    const grossFee = currPrice * 0.03;
    const baseFee = currPrice ? 1 : 0;
    const fee = baseFee + grossFee;
    return fee;
  }, [price, discountedAmount]);

  const subTotal = price + transactionFee - discountedAmount;

  const trackCheckoutButton = () => {
    mixpanel.track("Clicked Checkout Button", {
      "Credit Quantity": quantity,
      "Package ID": packageId,
    });
  };

  // FUT-1596: handleSubmit function for PurchasePage has to be a form submission
  // as a temp solution to allow rails to handle the redirect.
  // Using axios will result in a CORS preflight request, which could lead to
  // CORS issues when the browser tries to follow the redirect to Stripe.
  // Consider refactoring in future.
  const handleSubmit = async () => {
    setLoading(true);

    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute("content");

    const data = {
      authenticity_token: csrfToken,
      user_subscription: {
        package_id: packageId,
        user_id: userId,
        quantity: quantity,
        promo_code_id: promoCodeId,
      },
    };

    const form = document.createElement("form");

    // Set form attributes
    form.method = "POST";
    form.action = actionPath;

    const appendHiddenFields = (formData, parentKey = "") => {
      Object.keys(formData).forEach((key) => {
        const value = formData[key];
        const inputName = parentKey ? `${parentKey}[${key}]` : key;

        if (typeof value === "object" && value !== null) {
          // Recursively append nested objects
          appendHiddenFields(value, inputName);
        } else {
          // Create hidden input for non-object values
          const hiddenField = document.createElement("input");
          hiddenField.type = "hidden";
          hiddenField.name = inputName;
          hiddenField.value = value;
          form.appendChild(hiddenField);
        }
      });
    };

    // Append the nested form data to the form
    appendHiddenFields(data);

    // Add the form to the document body
    document.body.appendChild(form);

    // Submit the form
    form.submit();
  };

  return {
    setPromo,
    promoCodeId,
    setPromoCodeId,
    price,
    setPrice,
    discountedAmount,
    transactionFee,
    subTotal,
    trackCheckoutButton,
    handleSubmit,
    loading,
  };
};

export default usePurchase;
