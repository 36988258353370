// React
import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

// FutureLab
import { FutureLabApi } from "../../util/api";
import ProfileBasicForm from "./ProfileBasicForm";
import Toaster from "components/shared/Toaster";
import ProfilePersonalForm from "./ProfilePersonalForm";
import SocialProfileForm from "./SocialProfileForm";
import { UserProfile } from "./types";
import { profileSchema } from "./schema";

// UI
import { Button, Divider, Form } from "rsuite";

// 3rd Party
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";

const Profile = () => {
  const { user, setUser } = useOutletContext() as any;
  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);

  const methods = useForm<UserProfile>({
    resolver: yupResolver(profileSchema),
    defaultValues: {
      user: {
        firstname: user.firstname,
        lastname: user.lastname,
        user_languages:
          user.user_languages.map((lang) => lang.language_id) || [],
        profile_intro: user?.profile_intro || "",
      },
      info: {
        gender: user.info.gender,
        country_id: user.info.country_id,
        impact_to_create: user.info.impact_to_create || "",
        linkedin_url: user.info.linkedin_url || "",
      },
    },
  });

  useEffect(() => {
    FutureLabApi({
      method: "get",
      path: "/countries",
      success: ({ data }) => {
        setCountries(data);
      },
    });

    FutureLabApi({
      method: "get",
      path: "/languages",
      success: ({ data }) => {
        setLanguages(data);
      },
    });
  }, []);

  const onSubmit = (data) => {
    FutureLabApi({
      method: "post",
      path: "/users/current",
      options: data,
      success: ({ data }) => {
        setUser(data);
        Toaster({
          type: "success",
          msg: "Profile successfully updated",
        });
      },
      error: (err) => {
        const { error } = err.response?.data;
        Toaster({
          type: "error",
          msg: error || "Oops, something went wrong!",
        });
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <Form className="tw-mb-6">
        <ProfileBasicForm countries={countries} languages={languages} />
        <div className="tw-mt-12">
          <h6>Personal Details</h6>
          <Divider className="tw-mt-2" />
        </div>
        <ProfilePersonalForm />
        <div className="tw-mt-12">
          <h6>Social Profiles</h6>
          <Divider className="tw-mt-2" />
        </div>
        <SocialProfileForm />
        <div className="tw-text-right">
          <Button appearance="primary" onClick={methods.handleSubmit(onSubmit)}>
            Save changes
          </Button>
        </div>
      </Form>
    </FormProvider>
  );
};

export default Profile;
