import { ErrorMessage } from "@hookform/error-message";
import { yupResolver } from "@hookform/resolvers/yup";
import { validatePromoCode } from "api/subscriptions/promoApi";
import FieldError from "components/form/FieldError";
import Toaster from "components/shared/Toaster";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Form, Input, InputGroup, Message } from "rsuite";
import * as yup from "yup";

const schema = yup.object().shape({
  promo_code: yup.string().required("Promo code is required").nullable(),
});

const PromoCodeChecker = ({
  isMultiPackage,
  selectedPackage,
  setPromo,
  setPromoCodeId,
}) => {
  const [loading, setLoading] = useState(false);

  const disableSelection = isMultiPackage || !selectedPackage;
  const urlParams = new URLSearchParams(window.location.search);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { promo_code: "" },
    mode: "onSubmit",
  });

  useEffect(() => {
    const params = urlParams.get("promo_code");
    params && setValue("promo_code", params);
  }, []);

  const onSubmit = async ({ promo_code }) => {
    if (!promo_code) {
      return;
    }

    setLoading(true);
    try {
      const { data } = await validatePromoCode(promo_code);
      setPromoCodeId(data.id);
      setPromo(data);
      Toaster({
        type: "success",
        msg: "Promo code applied successfully",
      });
    } catch (err) {
      Toaster({
        type: "error",
        msg: "Promo code has expired or is invalid.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Message>
        Use <b>MENTOR15</b> to get 15% off for Single Sessions.
      </Message>
      <Form>
        <Controller
          name="promo_code"
          control={control}
          render={({ field }) => (
            <>
              <InputGroup inside className="tw-my-2">
                <Input {...field} placeholder="Promo Code" disabled={disableSelection} />
                <InputGroup.Button
                  disabled={disableSelection}
                  loading={loading}
                  onClick={handleSubmit(onSubmit)}
                >
                  <span
                    className="tw-mt-1"
                    style={{ lineHeight: "1.5", color: "#4299E1" }}
                  >
                    Apply
                  </span>
                </InputGroup.Button>
              </InputGroup>
              <ErrorMessage
                name="promo_code"
                control={control}
                errors={errors}
                as={FieldError}
              />
            </>
          )}
        />
      </Form>
    </div>
  );
};

export default PromoCodeChecker;
