// React
import React, { useState } from "react";

// FutureLab
import FieldError from "../ui/FieldError";
import { FutureLabApi } from "../../util/api";
import Toaster from "components/shared/Toaster";
import { UserPassword } from "./types";
import { updatePasswordSchema } from "./schema";

// UI
import { Button, Form, Input, InputGroup } from "rsuite";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";

// 3rd Party
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";

const UpdatePasswordForm = ({close}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UserPassword>({
    defaultValues: {
      password: "",
      password_confirmation: "",
      current_password: "",
    },
    resolver: yupResolver(updatePasswordSchema),
  });

  const [passwordVisible, setPasswordVisible] = useState({
    current: false,
    new: false,
    repeat: false,
  });

  const togglePasswordVisibility = (field: "current" | "new" | "repeat") => {
    setPasswordVisible((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const onSubmit = async (data) => {
   await FutureLabApi({
      method: "post",
      path: "/users/current/change_password",
      options: data,
      success: ({ data }) => {
        Toaster({
          type: "success",
          msg: "Password successfully updated",
        });
      },
      error: (err) => {
        const { errors } = err.response.data;
        Toaster({
          type: "error",
          msg: errors || "Oops, something went wrong!",
        });
      },
    });

    close()
  };

  return (
    <Form>
      <Form.Group>
        <Form.ControlLabel className="tw-text-sm tw-font-medium">
          Current password
        </Form.ControlLabel>
        <Controller
          name="current_password"
          control={control}
          render={({ field }) => (
            <InputGroup inside className="tw-w-full">
              <Input
                type={passwordVisible.current ? "text" : "password"}
                {...field}
              />
              <Button
                className="tw-bg-white"
                onClick={() => togglePasswordVisibility("current")}
                ripple={false}
              >
                {passwordVisible.current ? (
                  <FaRegEye size={22} />
                ) : (
                  <FaRegEyeSlash size={22} />
                )}
              </Button>
            </InputGroup>
          )}
        />
        <ErrorMessage errors={errors} name="current_password" as={FieldError} />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel className="tw-text-sm tw-font-medium">
          New password
        </Form.ControlLabel>
        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <InputGroup inside className="tw-w-full">
              <Input
                type={passwordVisible.new ? "text" : "password"}
                {...field}
              />
              <Button
                className="tw-bg-white"
                onClick={() => togglePasswordVisibility("new")}
                ripple={false}
              >
                {passwordVisible.new ? (
                  <FaRegEye size={22} />
                ) : (
                  <FaRegEyeSlash size={22} />
                )}
              </Button>
            </InputGroup>
          )}
        />
        <ErrorMessage errors={errors} name="password" as={FieldError} />
      </Form.Group>
      <Form.Group>
        <Form.ControlLabel className="tw-text-sm tw-font-medium">
          Confirm new password
        </Form.ControlLabel>
        <Controller
          name="password_confirmation"
          control={control}
          render={({ field }) => (
            <InputGroup inside className="tw-w-full">
              <Input
                type={passwordVisible.repeat ? "text" : "password"}
                {...field}
              />
              <Button
                className="tw-bg-white"
                onClick={() => togglePasswordVisibility("repeat")}
                ripple={false}
              >
                {passwordVisible.repeat ? (
                  <FaRegEye size={22} />
                ) : (
                  <FaRegEyeSlash size={22} />
                )}
              </Button>
            </InputGroup>
          )}
        />
        <ErrorMessage
          errors={errors}
          name="password_confirmation"
          as={FieldError}
        />
      </Form.Group>
      <div className="tw-text-right">
        <Button appearance="primary" onClick={handleSubmit(onSubmit)}>
          Save changes
        </Button>
      </div>
    </Form>
  );
};

export default UpdatePasswordForm;
