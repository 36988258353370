import React, { useState } from "react";
import AppContainer from "./AppContainer";
import { UserProvider } from "../contexts/user";
import moment from "moment";

import {
  Button,
  Col,
  Divider,
  FlexboxGrid,
  RadioTile,
  RadioTileGroup,
  Tag,
} from "rsuite";
import usePurchase from "./purchase/hooks/usePurchase";
import BookingMentorPreview from "./purchase/BookingMentorPreview";
import { PriceSubTotal, PriceSummary } from "./purchase/PriceComponents";
import PromoCodeChecker from "./purchase/PromoCodeChecker";

const PurchasePage = ({
  mentor,
  date = null,
  time = null,
  userId,
  actionPath,
}) => {
  const [selectedPackage, setSelectedPackage] = useState("");
  const [packageId, setPackageId] = useState(10);
  const isMultiPackage = selectedPackage === "multi";

  const bookingTime = time || null;
  const bookingDate = moment(date, "DD/MM/YYYY") || null;

  const {
    setPromo,
    setPromoCodeId,
    price,
    setPrice,
    discountedAmount,
    transactionFee,
    handleSubmit,
    subTotal,
    trackCheckoutButton,
    loading,
  } = usePurchase(packageId, userId, actionPath);

  // FUT-1596: Packages are hard coded at the moment,
  // Our PIC team have yet to filter and clean the packages in the prod DB.
  // Multi sessions cannot have promo codes applied.
  const packages = [
    {
      id: 10,
      value: "single",
      name: "Single Session",
      cost: 35,
      description: "Single Session - 1 credit",
      validity: "3 months",
      credits: 1,
      discount_tag: false,
      discount_amount: 0,
    },
    {
      id: 12,
      value: "multi",
      name: "Multi Session",
      cost: 60,
      description: "Multi Session - 3 credits",
      validity: "6 months",
      credits: 10,
      discount_tag: true,
      discount_amount: 43,
    },
  ];

  const handlePackageChange = (value) => {
    setSelectedPackage(value);
    setPackageId(value === "single" ? 10 : 12);
    setPrice(value === "single" ? 35 : 60);
    setPromo({ promo: false, discount: 0 });
  };

  const handlePurchase = () => {
    handleSubmit();
    trackCheckoutButton();
  };

  return (
    <UserProvider>
      <AppContainer>
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item as={Col} xs={24} lg={16} className="sm:tw-px-4">
            <Button
              appearance="link"
              onClick={() => window.history.back()}
              className="tw-my-4 tw-p-0"
            >
              Back
            </Button>

            <h1 className="tw-text-3xl">Checkout</h1>
            <Divider />

            <div className="tw-grid tw-grid-cols-3 tw-gap-4 sm:tw-grid-cols-1 ">
              <div className="tw-col-span-2">
                {mentor && date && time && (
                  <BookingMentorPreview
                    mentor={mentor}
                    bookingDate={bookingDate}
                    bookingTime={bookingTime}
                  />
                )}

                <p className="tw-font-bold">Choose your package</p>
                <RadioTileGroup
                  onChange={(value) => handlePackageChange(value as string)}
                >
                  {packages.map((p) => (
                    <RadioTile
                      key={p.id}
                      label={p.name}
                      value={p.value}
                      className="tw-block"
                    >
                      <div className="tw-flex tw-justify-between">
                        <div>
                          <p>{p.description}</p>
                          <span>Valid for {p.validity}</span>
                        </div>
                        <div>
                          <p className="tw-text-lg">
                            <b>RM {p.cost}</b>
                          </p>
                          {p.discount_tag && (
                            <Tag
                              color="blue"
                              style={{
                                position: "absolute",
                                top: 10,
                                right: 10,
                              }}
                            >
                              Save {p.discount_amount}%
                            </Tag>
                          )}
                        </div>
                      </div>
                    </RadioTile>
                  ))}
                </RadioTileGroup>
              </div>

              <div className="tw-col-span-1 sm:tw-col-span-2 tw-border tw-p-5 tw-rounded-xl tw-border-solid tw-border-gray-200">
                <PriceSummary
                  price={price}
                  discountedAmount={discountedAmount}
                  transactionFee={transactionFee}
                />
                <Divider />

                <PromoCodeChecker
                  isMultiPackage={isMultiPackage}
                  selectedPackage={selectedPackage}
                  setPromo={setPromo}
                  setPromoCodeId={setPromoCodeId}
                />
                <Divider />

                <PriceSubTotal subTotal={subTotal} />
                <Button
                  appearance="primary"
                  block
                  disabled={!selectedPackage}
                  onClick={handlePurchase}
                  loading={loading}
                >
                  Checkout
                </Button>
                <p className="tw-text-sm tw-py-2">
                  By clicking checkout, you agree that this credit will be
                  active from the date of payment and will be valid for a total
                  of {isMultiPackage ? "6 months" : "3 months"}. By purchasing
                  you also agree to our{" "}
                  <a href="https://www.futurelab.my/terms-of-service">
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a href="https://www.futurelab.my/privacy">Privacy Policy</a>.
                </p>
              </div>
            </div>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </AppContainer>
    </UserProvider>
  );
};

export default PurchasePage;
