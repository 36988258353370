// React
import React from "react";

// FutureLab
import UpdatePasswordForm from "./UpdatePasswordForm";

// UI
import { Divider, Modal } from "rsuite";

const UpdatePasswordModal = ({ isUpdatingPassword, close }) => {
  return (
    <Modal
      open={isUpdatingPassword}
      onClose={close}
      backdrop="static"
      size="xs"
    >
      <Modal.Header>
        <Modal.Title className="tw-text-xl tw-font-medium">
          Change Password
        </Modal.Title>
        <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />
      </Modal.Header>
      <Modal.Body className="tw-mt-8">
        <UpdatePasswordForm close={close} />
      </Modal.Body>
    </Modal>
  );
};

export default UpdatePasswordModal;
