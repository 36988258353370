// React
import React from "react";

// FutureLab
import JobForm from "components/edit_mentor_profile/JobForm";

// UI
import { Divider, Modal } from "rsuite";

const JobModal = ({
  job,
  companies,
  handleSearch,
  showEditModal,
  closeJobModal,
  initializedDeleteJobConfimartionModal,
  loading,
}) => {
  return (
    <Modal open={showEditModal} onClose={closeJobModal} size="xs">
      <Modal.Header>
        <Modal.Title className="tw-text-xl tw-font-medium">
          {job ? "Edit" : "Add"} Work Experience
        </Modal.Title>
        <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />
      </Modal.Header>
      <Modal.Body className="no-scrollbar">
        <JobForm
          companies={companies}
          job={job}
          handleSearch={handleSearch}
          loading={loading}
          closeJobModal={closeJobModal}
          initializedDeleteJobConfimartionModal={
            initializedDeleteJobConfimartionModal
          }
        />
      </Modal.Body>
    </Modal>
  );
};

export default JobModal;
