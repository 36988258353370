// React
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// FutureLab
import useEditRoute from "./useEditRoute";
import { findNavByEventKey } from "components/portal/admin/components/utils/navRouteMatcher";
import Navigation from "components/shared/navigation/Navigation";

const SideNav = () => {
  const { navItems, activeRoute } = useEditRoute();
  const currentActive = activeRoute?.eventKey || "1";
  const [activeKey, setActiveKey] = useState(currentActive);

  const navigate = useNavigate();

  const handleSelect = (eventKey) => {
    const selectedItem = findNavByEventKey(eventKey, navItems);

    if (selectedItem?.remote) {
      window.location.href = selectedItem.path;
    } else if (selectedItem?.path) {
      navigate(`.${selectedItem.path}`);
      setActiveKey(eventKey);
    }
  };

  return (
    <Navigation
      navItems={navItems}
      handleSelect={handleSelect}
      activeKey={activeKey}
    />
  );
};

export default SideNav;
