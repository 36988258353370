// React
import React from "react";

// FutureLab
import CollegeForm from "components/edit_mentor_profile/CollegeForm";

// UI
import { Divider, Modal } from "rsuite";

const CollegeModal = ({
  college,
  universities,
  handleSearch,
  showEditModal,
  closeCollegeModal,
  loading,
  initializedDeleteCollegeConfimartionModal,
}) => {
  return (
    <Modal open={showEditModal} onClose={closeCollegeModal} size="xs">
      <Modal.Header>
        <Modal.Title className="tw-text-xl tw-font-medium">
          {college ? "Edit" : "Add"} Education
        </Modal.Title>
        <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />
      </Modal.Header>
      <Modal.Body>
        <CollegeForm
          college={college}
          universities={universities}
          handleSearch={handleSearch}
          closeCollegeModal={closeCollegeModal}
          loading={loading}
          initializedDeleteCollegeConfimartionModal={
            initializedDeleteCollegeConfimartionModal
          }
        />
      </Modal.Body>
    </Modal>
  );
};

export default CollegeModal;
