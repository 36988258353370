// React
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";

// FutureLab
import DeleteAccountModal from "./DeleteAccountModal";
import UpdatePasswordModal from "./UpdatePasswordModal";

// UI
import { Button, Divider, Form, Input } from "rsuite";

const Account = () => {
  const { user } = useOutletContext() as any;
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);

  return (
    <>
      <Form>
        <Form.Group>
          <Form.ControlLabel className="tw-flex tw-justify-between">
            <span className="tw-text-sm tw-font-medium">Email</span>
          </Form.ControlLabel>
          <Input disabled defaultValue={user.email} />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel className="tw-flex tw-justify-between">
            <span className="tw-text-sm tw-font-medium">Password</span>
            <Button
              appearance="link"
              onClick={() => setIsUpdatingPassword(true)}
              className="tw-p-0"
            >
              Update
            </Button>
          </Form.ControlLabel>
          <Input disabled defaultValue={"********"} />
        </Form.Group>
      </Form>
      {isUpdatingPassword && (
        <UpdatePasswordModal
          isUpdatingPassword={isUpdatingPassword}
          close={() => setIsUpdatingPassword(false)}
        />
      )}
      <Divider />
      <div className="tw-text-right">
        <Button
          className="tw-text-[#D12F1D] tw-text-base tw-p-0"
          appearance="link"
          onClick={() => setShowDeleteConfirm(true)}
        >
          Delete Account
        </Button>
      </div>
      <DeleteAccountModal
        setShowDeleteConfirm={setShowDeleteConfirm}
        showDeleteConfirm={showDeleteConfirm}
      />
    </>
  );
};

export default Account;
