// React
import React from "react";

// FutureLab
import profilePhotoGuide from "../../images/profile_photo_guide.png";

// UI
import { FaCheckCircle } from "react-icons/fa";
import { Button, Divider, Modal } from "rsuite";

const ProfilePhotoGuideModal = ({ open, handleClose }) => {
  return (
    <Modal open={open} onClose={handleClose} className="tw-mt-16" size="xs">
      <Modal.Header>
        <Modal.Title className="tw-text-xl tw-font-medium">
          Profile photo guide
        </Modal.Title>
        <Divider className="tw-absolute tw-w-full tw-left-0 tw-mt-4 tw-bg-gray-300" />
      </Modal.Header>
      <Modal.Body>
        <div className="tw-text-center tw-py-7">
          <img src={profilePhotoGuide} alt="" />
          <div className="tw-text-green-600 tw-italic tw-mt-4 tw-text-xs">
            <FaCheckCircle className="tw-mr-2" />
            Perfect!
          </div>
        </div>
        <div>
          <h6>QUICK TIPS!</h6>
          <ul>
            <li>
              Avoid full-body shots, a close-up photo of your face would be
              better
            </li>
            <li>Ensure the photo is well lit!</li>
            <li>Recommended resolution: 1080 x 1080</li>
          </ul>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose} appearance="primary" block>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProfilePhotoGuideModal;
