// React
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";

// FutureLab
import Api, { FutureLabApi } from "../../util/api";
import CollegeModal from "./CollegeModal";
import Toaster from "components/shared/Toaster";
import RemoveCollegeConfirmationModal from "./RemoveCollegeConfirmationModal";
import EducationBackground from "./EducationBackground";
import { CollegeInput } from "./types";

// UI
import { Button } from "rsuite";

const fetchUniverisities = async (query = "") => {
  return await Api.get("/universities", { search: query });
};

const Education = () => {
  const { refreshUser } = useOutletContext() as any;
  const [universities, setUniversities] = useState<[]>();
  const [college, setCollege] = useState<CollegeInput | null>();
  const [loading, setLoading] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const initializedDeleteCollegeConfimartionModal = () =>
    setShowDeleteConfirm(true);

  const closeCollegeModal = () => setShowEditModal(false);
  const closeCollegeConfirmationModal = () => setShowDeleteConfirm(false);

  const handleSearch = async (query) => {
    setLoading(true);
    try {
      const { data } = await fetchUniverisities(query);
      setUniversities(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const initializedCollegeModal = async (selectedCollege = null) => {
    await handleSearch(selectedCollege?.university?.name);
    setCollege(selectedCollege);
    setShowEditModal(true);
  };

  const deleteCollege = async () => {
    await FutureLabApi({
      method: "delete",
      path: `/colleges/${college.id}`,
      success: () => {
        refreshUser();
        Toaster({
          type: "success",
          msg: "University successfully deleted",
        });
      },
    });

    closeCollegeConfirmationModal();
    closeCollegeModal();
  };

  return (
    <>
      <div className="tw-flex tw-justify-between">
        <h6 className="tw-text-base">Education</h6>
        <Button appearance="link" onClick={() => initializedCollegeModal()}>
          Add new
        </Button>
      </div>
      <EducationBackground initializedCollegeModal={initializedCollegeModal} />
      <CollegeModal
        college={college}
        universities={universities}
        handleSearch={handleSearch}
        showEditModal={showEditModal}
        closeCollegeModal={closeCollegeModal}
        loading={loading}
        initializedDeleteCollegeConfimartionModal={
          initializedDeleteCollegeConfimartionModal
        }
      />
      <RemoveCollegeConfirmationModal
        showDeleteConfirm={showDeleteConfirm}
        closeCollegeConfirmationModal={closeCollegeConfirmationModal}
        deleteCollege={deleteCollege}
      />
    </>
  );
};

export default Education;
