// React
import React from "react";

// FutureLab
import { FormInputLinkedin } from "components/form/default/FormInputs";

// UI
import { Form } from "rsuite";

// 3rd Party
import { useFormContext } from "react-hook-form";

const SocialProfileForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Form.Group>
      <FormInputLinkedin
        name="info.linkedin_url"
        label="LinkedIn"
        control={control}
        errors={errors}
        size="lg"
      />
    </Form.Group>
  );
};

export default SocialProfileForm;
