import * as yup from "yup";

export const availabilitySchema = yup.object().shape({
  user: yup.object().shape({
    timezone: yup.string().required().label("Timezone").nullable(),
  }),
  availability: yup.object().shape({
    dnd: yup.boolean().nullable(),
  }),
});

export const collegeSchema = yup.object().shape({
  university_name: yup
    .string()
    .trim()
    .max(60, "University name must be less than 60 characters")
    .test(
      "no-urls",
      "URLs are not allowed",
      (value) => !/(www|http|https)/i.test(value)
    )
    .required()
    .label("University")
    .nullable(),
  level: yup.string().required().label("Qualification").nullable(),
  program_name: yup.string().required().label("Major"),
  start_date: yup.string().required().label("Start date").nullable(),
  end_date: yup.string().label("End date").nullable(),
  study_status: yup.number().label("Study status"),
  // cgpa: yup.string().label("CGPA"),
});

export const deletionSchema = yup.object().shape({
  reason_for_leaving: yup
    .array()
    .min(1, "Reasons is a required field")
    .required()
    .label("Reasons"),
});

export const experienceSchema = yup.object().shape({
  user_industries_attributes: yup.array().nullable(),
  services_attributes: yup.array().nullable(),
});

export const jobSchema = yup.object().shape({
  name: yup.string().required().label("Job title"),
  company_name: yup
    .string()
    .trim()
    .max(60, "Company name must be less than 60 characters")
    .test(
      "no-urls",
      "URLs are not allowed",
      (value) => !/(www|http|https)/i.test(value)
    )
    .required()
    .label("Company")
    .nullable(),
  start_date: yup.string().required().label("Start date").nullable(),
  end_date: yup.string().label("End date").nullable(),
  description: yup.string().label("Description"),
  is_current: yup.bool(),
});

export const matchingSchema = yup.object().shape({
  industries_attributes: yup.array().nullable(),
  companies_attributes: yup.array().nullable(),
  goals_attributes: yup.array().nullable(),
  info: yup.object().shape({
    working_experience: yup.string().nullable(),
  }),
});

export const notificationSchema = yup.object().shape({
  user: yup.object().shape({
    phone_no: yup.string().required().label("Phone number"),
    whatsapp_notification: yup.boolean(),
  }),
});

export const profileSchema = yup.object().shape({
  user: yup.object().shape({
    firstname: yup.string().required().label("First name"),
    lastname: yup.string().required().label("Last name"),
    profile_intro: yup.string().label("About me"),
  }),
  info: yup.object().shape({
    country_id: yup.string().required().label("Country").nullable(),
    gender: yup.string().label("Gender").nullable(),
    impact_to_create: yup.string().label("Impact"),
    linkedin_url: yup.string().label("LinkedIn URL"),
  }),
});

export const updatePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .nullable()
    .test({
      name: "password",
      message:
        "Password must contain at least 8 characters, 1 uppercase, 1 number and 1 special character",
      test: (value) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;
        return regex.test(value);
      },
    }),
  password_confirmation: yup
    .string()
    .required("Password confirmation is required")
    .nullable()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
  current_password: yup.string().required().label("Current password"),
});
