// React
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";

// UI
import { Avatar, Button, Divider } from "rsuite";
import { FaGraduationCap } from "react-icons/fa6";

// 3rd Party
import { DateTime } from "luxon";

const levels = {
  not_specified: "Not specified",
  high_school: "High School Graduate",
  pre_university: "Pre University Graduate",
  diploma: "Diploma",
  bachelors: "Bachelors",
  masters: "Masters",
  phd: "PhD",
  postdoctarate: "Postdoctorate",
  other: "Others",
};

const EducationBackground = ({ initializedCollegeModal }) => {
  const { user } = useOutletContext() as any;
  const [visibleCollegesCount, setVisibleCollegesCount] = useState(2);

  const toggleVisibleColleges = () => {
    if (visibleCollegesCount === 2) {
      setVisibleCollegesCount(user.colleges.length);
    } else {
      setVisibleCollegesCount(2);
    }
  };

  return (
    <div className="tw-w-full tw-p-3 tw-overflow-y-auto">
      {user.colleges ? (
        <>
          {user?.colleges.slice(0, visibleCollegesCount).map((college, idx) => (
            <div
              key={college.id}
              onClick={() => initializedCollegeModal(college)}
              className="hover:tw-cursor-pointer hover:tw-opacity-70"
            >
              <div className="tw-mx-2">
                <div className="tw-flex">
                  <div className="tw-mr-6">
                    <Avatar className="tw-size-12">
                      <FaGraduationCap size={30} />
                    </Avatar>
                  </div>
                  <div className="tw-flex tw-flex-col tw-flex-grow">
                    <div className="tw-flex tw-justify-between">
                      <h6 className="tw-mb-1 tw-text-sm">
                        {levels[college.level]}
                        {college.level && college.program_name && " of "}
                        {college.program_name}
                      </h6>
                      <p className="tw-text-xs tw-font-medium tw-mb-1">
                        {DateTime.fromISO(college.start_date).toFormat(
                          "MMM yyyy"
                        )}{" "}
                        -{" "}
                        {college.end_date
                          ? DateTime.fromISO(college.end_date).toFormat(
                              "MMM yyyy"
                            )
                          : "Present"}
                      </p>
                    </div>
                    <p className="tw-font-medium tw-text-sm tw-mb-1">
                      {college.university.name}
                    </p>
                  </div>
                </div>
              </div>

              {idx !== visibleCollegesCount - 1 &&
                idx !== user.colleges.length - 1 && (
                  <Divider className="tw-my-4" />
                )}
            </div>
          ))}

          {user.colleges.length > 2 && (
            <div className="tw-text-center tw-mt-4">
              <Button appearance="link" onClick={toggleVisibleColleges}>
                {visibleCollegesCount === 2 ? "View More" : "View Less"}
              </Button>
            </div>
          )}
        </>
      ) : (
        <p>
          Share your educational background and showcase your learning path.
        </p>
      )}
    </div>
  );
};

export default EducationBackground;
