// React
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

// FutureLab
import { useUser } from "contexts/user";
import { findNavByActiveRoutePath } from "components/portal/admin/components/utils/navRouteMatcher";

const navItems = [
  {
    eventKey: "1",
    label: "Account",
    path: "/",
    description: "Manage your account details and credentials",
  },
  {
    eventKey: "2",
    label: "Profile",
    path: "/profile",
    description: "Showcase who you are personally",
  },
  {
    eventKey: "3",
    label: "Mentorship",
    path: "/mentorship",
    description: "Optimize your matching preferences",
  },
  {
    eventKey: "4",
    label: "Experience",
    path: "/experience",
    description: "Highlight work experience and education",
  },
  {
    eventKey: "5",
    label: "Availability",
    path: "/availability",
    description: "Set your schedule for mentoring sessions",
  },
  {
    eventKey: "6",
    label: "Notification",
    path: "/notification",
    description: "Customize your notification setting",
  },
];

const useEditRoute = () => {
  const { user } = useUser();
  const { pathname } = useLocation();

  const url = `/users/${user?.slug}/edit`;
  const activePath = pathname.replace(url, "") || '/';

  const activeRoute = useMemo(
    () => findNavByActiveRoutePath(activePath, navItems),
    [activePath, navItems]
  );

  return {
    navItems,
    activeRoute,
  };
};

export default useEditRoute;
