// React
import React from "react";

// FutureLab
import { FormInputText } from "components/form/default/FormInputs";

// UI
import { Form } from "rsuite";

// 3rd Party
import { useFormContext } from "react-hook-form";

const ProfilePersonalForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Form.Group>
        <FormInputText
          name="user.profile_intro"
          label={"About me"}
          control={control}
          errors={errors}
          as="textarea"
          size="lg"
          rows={4}
        />
      </Form.Group>
      <Form.Group>
        <FormInputText
          name="info.impact_to_create"
          label="The impact I want to make"
          control={control}
          errors={errors}
          as="textarea"
          size="lg"
          rows={4}
        />
      </Form.Group>
    </>
  );
};

export default ProfilePersonalForm;
