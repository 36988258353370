// React
import React from "react";

// UI
import { Button, Modal } from "rsuite";
import { FaExclamationTriangle } from "react-icons/fa";

const RemoveJobConfirmationModal = ({
  showDeleteConfirm,
  closeJobConfirmationModal,
  deleteJob,
}) => {
  return (
    <Modal backdrop="static" open={showDeleteConfirm} size="xs">
      <Modal.Body className="tw-text-center">
        <FaExclamationTriangle className="tw-mr-4" color="#d12f1d" />
        Are you sure you want to delete this experience?
      </Modal.Body>
      <Modal.Footer className="text-center">
        <Button onClick={deleteJob} appearance="primary">
          Confirm
        </Button>
        <Button onClick={closeJobConfirmationModal} appearance="default">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveJobConfirmationModal;
