// React
import React, { useMemo } from "react";

// UI
import { IconButton } from "rsuite";
import { FaDownload } from "react-icons/fa";

const escapeCSVValue = (value) => {
  if (typeof value === "string") {
    // Escape double quotes by doubling them, and wrap the value in quotes if it contains commas, newlines, or quotes
    return `"${value.replace(/"/g, '""')}"`;
  }
  return value;
};

const getRowData = (rowData) => {
  return [
    rowData.full_name,
    rowData.email,
    rowData.applied_on,
    ...rowData.answers.map((answer) => escapeCSVValue(answer)),
  ];
};

const getRowHeaders = (questions) => [
  "Name",
  "Email",
  "Applied on",
  ...questions.map((question) => escapeCSVValue(question)),
];

export default function DownloadButton(props) {
  const { data, fileName, questions } = props;

  const csvData = useMemo(() => {
    const cellData = data.map(getRowData);
    return [getRowHeaders(questions), ...cellData];
  }, [data, questions]);

  const handleDownloadCSV = () => {
    // Create a data URI for the CSV content
    const csvContent = encodeURIComponent(
      csvData.map((row) => row.join(",")).join("\n")
    );

    const csvURL = `data:text/csv;charset=utf-8,${csvContent}`;

    // Create a temporary <a> element to trigger the download
    const link = document.createElement("a");
    link.setAttribute("href", csvURL);
    link.setAttribute("download", `${fileName}`);
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  return (
    <IconButton
      icon={<FaDownload size={16} />}
      appearance="ghost"
      className="tw-mb-1 tw-mr-2"
      onClick={handleDownloadCSV}
    />
  );
}
